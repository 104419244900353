import React, { Component } from 'react'
import Header from '../components/Header'
import { grey } from '@mui/material/colors';
import { Grid } from '@mui/material';
import { Container } from '@mui/system';
import Modules from '../components/Modules';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { PeopleAltOutlined, MailOutline, AdminPanelSettings, ListAltOutlined, Settings } from '@mui/icons-material'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setJWT, setName } from '../redux/actions/actions';
import { Navigate } from 'react-router-dom';

export class Dashboard extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        redirect: false,
        redirectTo: '',
      }
    }

    checkLogin = () => {
        if(this.props.jwtToken == ''){
            this.setState({ redirectTo: '/' });
            this.setState({ redirect: true });
        }
    }

    componentDidMount(){
        this.checkLogin();
    }

    componentDidUpdate(prevProps){
        if(this.props.jwtToken != prevProps.jwtToken){
			this.checkLogin();
		}
    }

    render() {
        return (
            <div style={{ backgroundColor: grey['200'], minHeight: '100vh' }}>
                {this.state.redirect == true ?
                    <Navigate to={this.state.redirectTo} />
                    : null
                }
                <Header />
                <div  >
                    <Container>
                        <Grid container spacing={2} paddingY={2} direction='row'>
                            <Grid item>
                                <Card sx={{ minWidth: 200 }}>
                                    <CardContent>
                                        <Link to='/kgpians' style={{ textDecoration: 'none' }}>
                                            <Grid container direction='column'>
                                                <Grid item display='flex' justifyContent='center'>
                                                    <PeopleAltOutlined sx={{ fontSize: 80 }} color="primary" />
                                                </Grid>
                                                <Grid item display='flex' justifyContent='center'>
                                                    <Typography variant='h6' color={grey[500]}>KGPians</Typography>
                                                </Grid>
                                            </Grid>
                                        </Link>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item>
                                <Card sx={{ minWidth: 200 }}>
                                    <CardContent>
                                        <Link to='/email-logs' style={{ textDecoration: 'none' }}>
                                            <Grid container direction='column'>
                                                <Grid item display='flex' justifyContent='center'>
                                                    <MailOutline sx={{ fontSize: 80 }} color="primary" />
                                                </Grid>
                                                <Grid item display='flex' justifyContent='center'>
                                                    <Typography variant='h6' color={grey[500]}>EMail Logs</Typography>
                                                </Grid>
                                            </Grid>
                                        </Link>
                                    </CardContent>
                                </Card>
                            </Grid> 
                            <Grid item>
                                <Card sx={{ minWidth: 200 }}>
                                    <CardContent>
                                        <Link to='#' style={{ textDecoration: 'none' }}>
                                            <Grid container direction='column'>
                                                <Grid item display='flex' justifyContent='center'>
                                                    <AdminPanelSettings sx={{ fontSize: 80 }} color="disabled" />
                                                </Grid>
                                                <Grid item display='flex' justifyContent='center'>
                                                    <Typography variant='h6' color={grey[500]}>Administrators</Typography>
                                                </Grid>
                                            </Grid>
                                        </Link>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item>
                                <Card sx={{ minWidth: 200 }}>
                                    <CardContent>
                                        <Link to='/settings' style={{ textDecoration: 'none' }}>
                                            <Grid container direction='column'>
                                                <Grid item display='flex' justifyContent='center'>
                                                    <Settings sx={{ fontSize: 80 }} color="primary" />
                                                </Grid>
                                                <Grid item display='flex' justifyContent='center'>
                                                    <Typography variant='h6' color={grey[500]}>Settings</Typography>
                                                </Grid>
                                            </Grid>
                                        </Link>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                    </Container>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        name: state.name,
        jwtToken: state.jwtToken,
    }
}

const mapDispatchToProps = {
    setJWT, setName
}

export default connect(mapStateToProps,mapDispatchToProps)(Dashboard);